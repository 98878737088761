<template>
  <div>
    <ValidationObserver ref="form">
      <v-row>
        <v-col>
          <v-card>
            <v-toolbar elevation="0">
              <v-btn @click="$router.go(-1)" icon>
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-toolbar-title class="primary--text">
                {{ pageTitle }}
              </v-toolbar-title>
            </v-toolbar>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" :loading="fetching_report">
          <v-card outlined>
            <v-card-title>{{
              $capitalize($tc("model.final_report_framing_title"))
            }}</v-card-title>
            <v-card-text>
              <ValidationProvider
                name="final_report.is_matched"
                v-slot="{ errors }"
              >
                <v-select
                  v-model="lead_form.is_matched"
                  :label="$capitalize($tc('model.final_report_framing_title'))"
                  :items="fitting_items"
                  outlined
                  :error-messages="errors"
                ></v-select>
              </ValidationProvider>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card outlined>
            <v-card-title>{{ __("Peritagem", "schedulings") }}</v-card-title>
            <v-card-text>
              <ValidationProvider
                rules="required"
                name="final_report.established_facts"
                v-slot="{ errors }"
              >
                <v-autocomplete
                  outlined
                  :label="
                    $capitalize($tc('model.visit_report_origin_cause_card'))
                  "
                  v-model="lead_form.origin_type_id"
                  item-value="id"
                  item-text="description"
                  :items="available_origin_types"
                  :clearable="!editMode"
                  :error-messages="errors"
                  :loading="fecthing_available_origin_types"
                  :readonly="editMode"
                />
              </ValidationProvider>
              <ValidationProvider
                rules="required"
                name="final_report.established_facts"
                v-slot="{ errors }"
              >
                <v-textarea
                  outlined
                  :label="__('fatos apurados', 'final_report')"
                  :rows="10"
                  auto-grow
                  v-model="lead_form.established_facts"
                  :error-messages="errors"
                  :readonly="editMode"
                ></v-textarea>
              </ValidationProvider>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card outlined>
            <v-card-title>{{
              $capitalize($tc("model.final_report_research_title"))
            }}</v-card-title>
            <v-card-text>
              <ValidationProvider
                name="final_report.needs_research"
                rules="required"
                v-slot="{ errors }"
              >
                <v-autocomplete
                  :label="
                    $capitalize($tc('model.research_report_need_research'))
                  "
                  :items="[
                    {
                      value: true,
                      text: $capitalize($tc('model.research_report_yes_item')),
                    },
                    {
                      value: false,
                      text: $capitalize($tc('model.research_report_no_item')),
                    },
                  ]"
                  v-model="lead_form.needs_research"
                  :readonly="editMode"
                  outlined
                  :error-messages="errors"
                ></v-autocomplete>
              </ValidationProvider>
              <v-row>
                <v-col>
                  <ValidationProvider
                    name="final_report.research_justification"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      :items="list_research_justifications"
                      v-model="lead_form.research_justification"
                      item-value="code"
                      item-text="description"
                      :label="
                        $capitalize(
                          $tc('model.research_report_research_justification')
                        )
                      "
                      :error-messages="errors"
                      :readonly="editMode"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ValidationProvider
                    name="final_report.research_begin_date"
                    v-slot="{ errors }"
                  >
                    <DatePicker
                      :label="__('Data de início', 'schedulings')"
                      v-model="research_begin_date"
                      :error-messages="errors"
                      :readonly="editMode"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    :rules="lead_form.research_begin_date ? 'required' : ''"
                    name="final_report.research_begin_time"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      type="time"
                      :label="
                        $capitalize($tc('model.research_report_research_hour'))
                      "
                      v-model="research_begin_time"
                      :error-messages="errors"
                      :readonly="editMode"
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ValidationProvider
                    name="final_report.research_end_date"
                    v-slot="{ errors }"
                  >
                    <DatePicker
                      :label="__('Data de finalização', 'schedulings')"
                      v-model="research_end_date"
                      :error-messages="errors"
                      :readonly="editMode"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    :rules="lead_form.research_end_date ? 'required' : ''"
                    name="final_report.research_end_time"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      type="time"
                      :label="
                        $capitalize($tc('model.research_report_research_hour'))
                      "
                      v-model="research_end_time"
                      :error-messages="errors"
                      :readonly="editMode"
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <ValidationProvider
                :rules="{
                  required: lead_form.needs_research === true,
                  min_value: lead_form.needs_research === true ? 0.01 : 0,
                }"
                name="final_report.research_extimated_price"
                v-slot="{ errors }"
              >
                <MoneyInput
                  v-model="lead_form.research_extimated_price"
                  :label="
                    $capitalize($tc('model.research_report_estimated_value'))
                  "
                  :error-messages="errors"
                  :readonly="editMode"
                  outlined
                />
              </ValidationProvider>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div class="action-buttons">
        <v-btn
          fab
          color="primary"
          @click="save"
          :loading="saving_report"
          :disabled="!$store.getters['auth/getScope']('leads.final_report.add')"
          v-if="!editMode"
        >
          <v-icon> mdi-content-save-move</v-icon>
        </v-btn>
        <v-btn
          fab
          color="secondary"
          :href="report.attachment && report.attachment.public_url"
          target="_blank"
          v-if="report != null && report.attachment != null"
        >
          <v-icon> mdi-file-find </v-icon>
        </v-btn>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { RESEARCH_JUSTIFICATIONS } from "@/services/research_reports";
import { show as showReport, storeMultirisks } from "@/services/final_reports";

import MoneyInput from "@/components/MoneyInput";
import DatePicker from "@/components/DatePicker";
import { indexAvailableOriginTypes } from "@/services/lead";
import i18n from "@/mixins/i18n";

export default {
  components: { MoneyInput, DatePicker },
  mixins: [i18n],
  data: function () {
    return {
      participant_opened: null,
      lead_form: {
        established_facts: "",
        is_matched: null,
        research_extimated_price: 0,
        franchise_value: 0,
      },

      research_begin_date: "",
      research_begin_time: "",
      research_end_date: "",
      research_end_time: "",

      fetching_report: false,
      saving_report: false,
      report: {},
      lead_participants: [],

      available_origin_types: [],
      fecthing_available_origin_types: false,

      fitting_items: [
        {
          text: this.$capitalize(this.$tc("model.visit_report_to_be_defined")),
          value: null,
        },
        {
          text: this.$capitalize(
            this.$tc("model.damages_matched_classification")
          ),
          value: true,
        },
        {
          text: this.$capitalize(
            this.$tc("model.damages_mismatched_classification")
          ),
          value: false,
        },
      ],
    };
  },
  methods: {
    participantIsOpened(participant) {
      return (
        this.participant_opened && this.participant_opened?.id == participant.id
      );
    },
    openParticipant(participant) {
      this.participant_opened = this.participantIsOpened(participant)
        ? null
        : participant;
    },
    handleBudgetUpdate() {
      this.loadBudgetsByCoverages(this.$route.params["lead_number"]);
    },
    loadLeadForm() {
      const lead = this.editMode ? this.report.payload : this.lead;
      this.lead_form = {
        ...this.lead_form,
        origin_type_id: lead?.origin_type_id,
        is_matched: lead?.is_matched,
        origin_type_id: lead?.origin_type_id,
        needs_research: lead?.default_service?.needs_research,
        research_begin_date: lead?.default_service?.research_begin_date,
        research_end_date: lead?.default_service?.research_end_date,
        research_extimated_price:
          lead?.default_service?.research_extimated_price || 0,
        research_justification:
          lead?.default_service?.research_justification == null
            ? RESEARCH_JUSTIFICATIONS["insured"].code
            : lead?.default_service?.research_justification,
      };

      if (this.lead.default_service.research_begin_date) {
        const date_parts =
          this.lead.default_service.research_begin_date.split(" ");

        this.research_begin_date = date_parts[0];
        this.research_begin_time = date_parts[1];
      }

      if (this.lead.default_service.research_end_date) {
        const date_parts =
          this.lead.default_service.research_end_date.split(" ");

        this.research_end_date = date_parts[0];
        this.research_end_time = date_parts[1];
      }

      if (!this.editMode) {
        this.lead_form.established_facts = this.lead.established_facts;
      }
    },
    loadParticipants() {
      this.lead_participants = this.participants;
    },
    async fetchFinalReport() {
      const report_id = this.$route.params["final_report_id"];

      if (report_id) {
        this.fetching_report = true;
        const response = await showReport(
          this.$route.params["lead_number"],
          report_id
        );
        this.report = response;
        this.lead_form.established_facts = response.established_facts;
        this.fetching_report = false;
        this.loadLeadForm();
      }
    },
    setErrors(data) {
      if (data) {
        this.$store.commit("sendMessage", {
          text: data.message,
          color: "red",
        });
      }
    },
    async save() {
      if (!(await this.$refs.form.validate())) return;

      this.saving_report = true;
      try {
        if (!this.editMode) {
          const response = await this.create();
          const payload = response.payload;
          this.report.attachment = response.attachment;
          this.updateLead(payload);

          this.$store.commit("sendMessage", {
            text: "Relatório criado com sucesso",
            color: "green",
          });
          this.$router.push({ name: "lead_final_reports" });
        }
      } catch (e) {
        if (e?.response?.data?.errors) {
          this.$refs.form.setErrors(e?.response?.data.errors);
        }
        if (e?.response?.data?.message) {
          this.$store.commit("sendMessage", {
            text: e?.response?.data?.message,
            color: "red",
          });
        }
      } finally {
        this.saving_report = false;
      }
    },
    async create() {
      const response = await storeMultirisks(this.lead?.id, {
        final_report: {
          ...this.lead_form,
          participants: this.lead_participants,
        },
      });
      return response;
    },
    updateLead(payload) {
      this.$store.commit("setLead", {
        ...this.lead,
        origin_type_id: payload.origin_type_id,
        is_matched: payload.is_matched,
        established_facts: payload.established_facts,
        default_service: {
          ...this.lead.default_service,
          needs_research: payload.default_service.needs_research,
          research_begin_date: payload.default_service.research_begin_date,
          research_end_date: payload.default_service.research_end_date,
          research_extimated_price:
            payload.default_service.research_extimated_price,
          research_justification:
            payload.default_service.research_justification,
        },
      });
    },
    async update() {
      const response = await updateReport(this.lead?.id, this.report?.id, {
        final_report: {
          ...this.lead_form,
          participants: this.lead_participants,
        },
      });
      this.$store.commit("sendMessage", {
        text: "Relatório atualizado com sucesso",
        color: "green",
      });

      return response;
    },
    agreeWithCompensation(participant, agrees) {
      participant.agrees_with_compensation = agrees;
    },
    justifyDisagreementWithCompensation(participant, justification) {
      participant.agrees_with_compensation_justification = justification;
    },

    async loadAvailableOriginTypes() {
      this.fecthing_available_origin_types = true;

      try {
        const response = await indexAvailableOriginTypes(
          this.$route.params["lead_number"]
        );
        this.available_origin_types = response;
      } finally {
        this.fecthing_available_origin_types = false;
      }
    },
  },
  computed: {
    editMode() {
      return this.$route.params["final_report_id"] != null;
    },
    pageTitle() {
      if (this.editMode) return this.report?.attachment?.description;

      return this.$capitalize(this.$tc("model.reports_new_report_title"));
    },
    lead() {
      return this.$store.getters.getLead;
    },
    list_research_justifications() {
      return Object.values(RESEARCH_JUSTIFICATIONS);
    },
    final_report_id() {
      return this.$route.params["final_report_id"];
    },
  },
  created() {
    this.loadAvailableOriginTypes();
    this.loadLeadForm();
    this.fetchFinalReport();
  },
  watch: {
    lead: function (value, oldValue) {
      const old_lead_id = oldValue?.id;
      const lead_id = value?.id;

      if (old_lead_id == null && lead_id != null) {
        this.loadLeadForm();
      }
    },
    research_begin_date: function (value) {
      if (value && this.research_begin_time.length > 0) {
        this.lead_form.research_begin_date = `${this.research_begin_date} ${this.research_begin_time}`;
      } else {
        this.lead_form.research_begin_date = null;
      }
    },
    research_begin_time: function (value) {
      if (value && this.research_begin_date.length > 0) {
        this.lead_form.research_begin_date = `${this.research_begin_date} ${this.research_begin_time}`;
      } else {
        this.lead_form.research_begin_date = null;
      }
    },
    research_end_date: function (value) {
      if (value && this.research_end_time.length > 0) {
        this.lead_form.research_end_date = `${this.research_end_date} ${this.research_end_time}`;
      } else {
        this.lead_form.research_end_date = null;
      }
    },
    research_end_time: function (value) {
      if (value && this.research_end_date.length > 0) {
        this.lead_form.research_end_date = `${this.research_end_date} ${this.research_end_time}`;
      } else {
        this.lead_form.research_end_date = null;
      }
    },
  },
};
</script>

<style>
.action-buttons {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 4;
  height: 120px;
  right: 16px;
  bottom: 16px;
  position: fixed;
}
</style>
